import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 26, 21, 4],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.suspendUpdate();

brd1.options.layer['image'] =14;

//Title and subtitle
placeTitle(brd1, 'Decimal Places', '(Tap on Numbers to Find Decimal Places)');
//Variables
placeLogo(brd1);
var a = 1;
var b = 2;
var c = 3;
var d = 4;
var o = 2;
var j =1;
var txt_b = '2';
var txt_c = '3';
var txt_d = '4';
//brd1.create('image', ['/assets/oneR.svg', [9, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/twoR.svg', [11.5, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/threeR.svg', [14, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
var polynomial1 = brd1.create('text', [9.2, 16, function(){return  a + '.'  }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var polynomial2 = brd1.create('text', [10.5, 16, function(){return  '' + b + '' }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var polynomial3 = brd1.create('text', [11.5, 16, function(){return ' '+ c }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var polynomial4 = brd1.create('text', [12.5, 16, function(){return ' '+ d }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
polynomial1.on('down',function(){
      txt0.setAttribute({visible: true});
      dash0.setAttribute({visible: true});
  });
polynomial2.on('down',function(){
      txt1.setAttribute({visible: true});
      dash1.setAttribute({visible: true});
  });
polynomial3.on('down',function(){
      txt2.setAttribute({visible:true});
      dash2.setAttribute({visible: true});
    });
polynomial4.on('down',function(){
      txt3.setAttribute({visible:true});
      dash3.setAttribute({visible: true});
        });

var dash1 = placeLine(brd1, [10.5, 13], [10.5, 15.4], 2, 'red');
var dash2 = placeLine(brd1, [11.5, 11], [11.5, 15.4], 2, 'green');
var dash3 = placeLine(brd1, [12.5, 9], [12.5, 15.4], 2, 'blue');
var dash0 = placeLine(brd1, [9.5, 16.6], [9.5, 19.], 2, 'brown');
dash0.setAttribute({visible:false});
dash1.setAttribute({visible:false});
dash2.setAttribute({visible:false});
dash3.setAttribute({visible:false});
var txt0 = brd1.create('text', [9.3, 19., '1s Place'],{visible:false, display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'brown', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt1 = brd1.create('text', [10.3, 13, '10th Place'],{visible:false, display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'red', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt2 = brd1.create('text', [11.3, 11, '100th Place'],{visible:false,display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'green', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt3 = brd1.create('text', [12.3, 9, '1000th Place'],{visible:false,display:'internal',highlight:false, fixed: true, anchorX: 'right', anchorY: 'middle', color:'blue', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);

var analytics =placeImage(brd1, '/assets/shuffle.svg', 8.75, 5, 1.5, 0);
analytics.setLabel('Tap to Shuffle')
analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
analytics.on('over', function () {this.label.setAttribute({visible:true});});
analytics.on('out', function () {this.label.setAttribute({visible:false});});

var erase =placeImage(brd1, '/assets/eraser.svg', 11.5, 5, 1.5, 0);
erase.setLabel('Tap to Erase & Restart')
erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
erase.on('over', function () {this.label.setAttribute({visible:true});});
erase.on('out', function () {this.label.setAttribute({visible:false});});
//Erase Function
erase.on('down',function(){
  txt0.setAttribute({visible: false});
  dash0.setAttribute({visible: false});
  txt1.setAttribute({visible: false});
  dash1.setAttribute({visible: false});
  txt2.setAttribute({visible: false});
  dash2.setAttribute({visible: false});
  txt3.setAttribute({visible: false});
  dash3.setAttribute({visible: false});
});
//Shuffle function
analytics.on('down',function(){
    txt0.setAttribute({visible: false});
    dash0.setAttribute({visible: false});
    txt1.setAttribute({visible: false});
    dash1.setAttribute({visible: false});
    txt2.setAttribute({visible: false});
    dash2.setAttribute({visible: false});
    txt3.setAttribute({visible: false});
    dash3.setAttribute({visible: false});
    a = Math.round(Math.random()*9)+1;
    b = Math.round(Math.random()*3)+1;
    c = Math.round(Math.random()*6)+1;
    d = Math.round(Math.random()*7)+1;
    //coefficient1.Value() = 'ppp'; // I want to reset the input values
});
brd1.unsuspendUpdate();
}
}
export default Boxes;
